<template>
    <div class="main-page">
        <div class="header-content">
            <header-container v-mounted="() => (animate = true)" />
        </div>
        <airlines-logos />
        <works-container :animate="animate" />
        <best-selling-vue />
        <reviews-vue />
        <about-vue ref="about" />
        <regions-vue />
        <contact-vue />
    </div>
</template>

<script>
// Components
import AirlinesLogos from '@/common/UI/AirlinesLogos.vue'
import HeaderContainer from '@/components/MainPage/HeaderContainer.vue'
import WorksContainer from '@/components/WorksContainer.vue'
import BestSellingVue from '@/components/MainPage/BestSelling.vue'
import ReviewsVue from '@/components/ReviewsVue.vue'
import AboutVue from '@/components/MainPage/AboutVue.vue'
import ContactVue from '@/components/ContactVue.vue'
import RegionsVue from '@/components/RegionsVue.vue'

export default {
    components: {
        AirlinesLogos,
        HeaderContainer,
        WorksContainer,
        BestSellingVue,
        ReviewsVue,
        AboutVue,
        ContactVue,
        RegionsVue,
    },
    data() {
        return {
            animate: false,
        }
    },
    metaInfo: {
        title: '',
    },
    mounted() {
        this.$store.commit('stopQuoteLoading')

        if (this.$store.state.app.scrollToAboutUs) {
            this.$store.commit('setAboutUs', false)

            setTimeout(() => {
                window.scrollTo({
                    top: this.$refs.about.$el.offsetTop,
                    behavior: 'smooth',
                })
            }, 100)
        }
    },
}
</script>

<style lang="scss">
@import 'v-calendar/style.css';

.main-page {
    position: relative;
    .reviews-container {
        margin-bottom: 80px;
    }
}
</style>
